<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header title="返回" @back="goBack" content="奖品详情"></el-page-header>
            </el-col>
            <el-col style="text-align: right" :span="12">
              <el-button type="primary" icon="el-icon-check" @click="saveCategoryModel">保存</el-button>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-form
          class="edit-form"
          :model="prize_model"
          ref="modelForm"
          label-width="150px"
          :rules="rules"
        >
          <el-form-item label="奖品名称:" prop="name">
            <el-input v-model="prize_model.name" clearable></el-input>
          </el-form-item>
          <el-form-item label="活动名称:" prop="activity_id">
            <el-select v-model="prize_model.activity_id" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in lottery_type_list"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              ></el-option>
            </el-select>
          </el-form-item>

          <!-- 优惠券和兑换码至少选择一个后才有module_id -->
          <el-form-item label="兑奖方式:">
            <el-select v-model="prize_model.module_type" placeholder="请选择" loading-text="加载中...">
              <el-option
                v-for="item in redemption_method_list"
                :key="item.module_type"
                :label="item.name"
                :value="item.module_type"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="prize_model.module_type>0"
            :label="prize_model.module_type===1000?'优惠券ID:':'兑换码批次ID:'"
            prop="module_id"
          >
            <el-input-number :min="1" v-model="prize_model.module_id"></el-input-number>
          </el-form-item>
          <el-form-item label="剩余奖品数量:" prop="total">
            <el-input v-model="prize_model.remain_count" clearable></el-input>
          </el-form-item>
          <el-form-item label="奖品说明:" prop="detail">
            <el-input v-model="prize_model.detail" clearable></el-input>
          </el-form-item>
          <el-form-item label="奖品权重:" prop="number">
            <el-input-number v-model="prize_model.number" clearable></el-input-number>
          </el-form-item>
          <el-form-item label="奖品级别:" prop="grade">
            <!-- <el-input v-model="prize_model.grade" clearable></el-input> -->
            <el-select v-model="prize_model.grade">
              <el-option v-for="(v, k) in gradeStatus" :key="k" :label="v" :value="k * 1"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item style="text-align: left" label="PC端图片:">
            <el-input v-model="prize_model.pc_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="prize_model.pc_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                  :preview-src-list="[prize_model.pc_image_url]"
                  lazy
                  fit="contain"
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('pc_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="text-align: left" label="WAP端图片:">
            <el-input v-model="prize_model.wap_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="prize_model.wap_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                  :preview-src-list="[prize_model.wap_image_url]"
                  lazy
                  fit="contain"
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('wap_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <el-form-item style="text-align: left" label="展示图片:">
            <el-input v-model="prize_model.show_image_url" clearable></el-input>
            <el-row>
              <el-col :span="6">
                <el-image
                  style="width: 100px; height: 100px; margin-top: 10px"
                  :src="prize_model.show_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                  :preview-src-list="[prize_model.show_image_url]"
                  lazy
                  fit="contain"
                ></el-image>
              </el-col>
              <el-col :span="18">
                <dl>
                  <dt class="edit-row-dt">
                    <el-button
                      size="mini"
                      type="warning"
                      @click="getImageByWeb('show_image_url')"
                    >资源选择</el-button>
                  </dt>
                </dl>
              </el-col>
            </el-row>
          </el-form-item>
          <CopyPaste
            :button_status="this.prize_id !== undefined && this.prize_id.length > 0"
            @copyInfo="copyInfo(prize_model)"
            @pasteInfo="pasteInfo"
            ref="copyPaste"
          ></CopyPaste>
        </el-form>
      </el-main>
    </el-container>
    <FileChooseDrawerModule ref="prizeFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../components/CopyPaste.vue";
import { gradeStatus } from "../status/index";

export default {
  name: "PrizeDetailView",
  components: {
    CopyPaste,
    FileChooseDrawerModule
  },
  data() {
    return {
      gradeStatus,
      loading: false,
      prize_id: "",
      prize_model: {
        id: "",
        activity_id: "",
        name: "",
        total: "",
        detail: "",
        number: "",
        grade: "",
        pc_image_url: "",
        wap_image_url: "",
        show_image_url: "",
        // 0:实物+未中奖，1：优惠券
        module_type: 0,
        module_id: ""
      },
      redemption_method_list: [
        {
          module_type: 0,
          name: "无"
        },
        {
          module_type: 1000,
          name: "优惠券"
        },
        {
          module_type: 2000,
          name: "兑奖码"
        }
      ],
      lottery_type_list: {},
      rules: {
        activity_id: [
          { required: true, message: "请选择活动名称", trigger: "change" }
        ],
        module_id: [
          {
            required: true,
            message: "优惠券码或批次号不能为空",
            trigger: "change"
          }
        ],
        grade: [
          {
            required: true,
            message: "奖品级别不能为空",
            trigger: "change"
          }
        ]
      }
    };
  },
  created() {
    this.getLotteryTypeList();
    this.prize_id = this.$route.query.id;
    if (this.prize_id !== undefined && this.prize_id.length > 0) {
      this.getPrizeModel();
    }
  },
  methods: {
    getLotteryTypeList() {
      let _me = this;
      let params = {
        page_size: -1,
        page_number: 1,
        // 1.获取最新，2.获取最旧
        page_flag: "",
        sort_fields: ""
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/admin/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.lottery_type_list =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getPrizeModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.prize_id
      };
      _me.$common
        .httpPost("/api/activity/lottery/reward/admin/find/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.prize_model = res.data;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    saveCategoryModel() {
      let _me = this;
      _me.$refs["modelForm"].validate(valid => {
        if (valid) {
          if (_me.prize_id !== undefined && _me.prize_id.length > 0) {
            _me.updateCategoryModel();
          } else {
            _me.newCategoryModel();
          }
        } else {
          _me.$message({
            message: "请检查表单!",
            type: "error"
          });
        }
      });
    },
    updateCategoryModel() {
      let _me = this;
      // if (_me.prize_model.grade > 3) {
      //   _me.prize_model.grade = 0;
      // }
      let params = {
        id: _me.prize_model.id,
        activity_id: _me.prize_model.activity_id,
        name: _me.prize_model.name,
        count: _me.prize_model.remain_count,
        detail: _me.prize_model.detail,
        number: _me.prize_model.number,
        grade: _me.prize_model.grade,
        pc_image_url: _me.prize_model.pc_image_url,
        wap_image_url: _me.prize_model.wap_image_url,
        show_image_url: _me.prize_model.show_image_url,
        module_id: _me.prize_model.module_id,
        module_type: _me.prize_model.module_type
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/lottery/reward/admin/update/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newCategoryModel() {
      let _me = this;
      if (_me.prize_model.grade > 3) {
        _me.prize_model.grade = 0;
      }
      let params = {
        activity_id: _me.prize_model.activity_id,
        name: _me.prize_model.name,
        count: _me.prize_model.remain_count,
        detail: _me.prize_model.detail,
        number: _me.prize_model.number,
        grade: _me.prize_model.grade,
        pc_image_url: _me.prize_model.pc_image_url,
        wap_image_url: _me.prize_model.wap_image_url,
        show_image_url: _me.prize_model.show_image_url,
        module_id: _me.prize_model.module_id,
        module_type: _me.prize_model.module_type
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/lottery/reward/admin/add/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.prizeFileChooseDrawerModule.showChooseDrawer(function(
        chooseFileList
      ) {
        console.log("接收到选择的文件:", chooseFileList);
        if (chooseFileList.length > 0) {
          switch (imageType) {
            case "pc_image_url":
              _me.prize_model.pc_image_url = chooseFileList[0].file_url;
              break;
            case "wap_image_url":
              _me.prize_model.wap_image_url = chooseFileList[0].file_url;
              break;
            case "show_image_url":
              _me.prize_model.show_image_url = chooseFileList[0].file_url;
              break;
            default:
              break;
          }
        }
      });
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);

      // 跳转回指定路由
      // this.$router.push('/product-detail-mount')
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      this.prize_model = model;
      this.prize_model.id = this.prize_id;
    }
  }
};
</script>

<style scoped></style>
