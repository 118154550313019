<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <el-page-header
          class="page_header"
          title="返回"
          @back="
            () => {
              this.$router.go(-1);
            }
          "
        ></el-page-header>
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="开始时间:">
              <el-date-picker
                v-model="begin_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                v-model="end_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="抽奖列表:">
              <el-select v-model="id" clearable placeholder="请选择" loading-text="加载中...">
                <el-option key="class-all" label="所有" value></el-option>
                <el-option
                  v-for="item in lotteryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!--            <el-form-item label="中奖等级:">-->
            <!--              <el-select v-model="prize" clearable placeholder="请选择" loading-text="加载中...">-->
            <!--                <el-option key="class-all" label="所有" :value="-1"></el-option>-->
            <!--                <el-option-->
            <!--                    v-for="item in prize_list"-->
            <!--                    :key="item.id"-->
            <!--                    :label="item.name"-->
            <!--                    :value="item.id"-->
            <!--                ></el-option>-->
            <!--              </el-select>-->
            <!--            </el-form-item>-->
            <el-form-item label="导出状态:">
              <el-select v-model="export_status" clearable placeholder="请选择" loading-text="加载中...">
                <el-option
                  v-for="item in import_doc_status_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="status" clearable placeholder="请选择" loading-text="加载中...">
                <el-option
                  v-for="item in status_list"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
                @keyup.enter.native="search"
              ></el-input>
            </el-form-item>
            <el-button class="top-button" size="medium" type="primary" round @click="search">过滤</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="recordList"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
          :default-sort="{ prop: 'count' }"
        >
          <el-table-column label="#" width="80">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="抽奖码/抽奖凭证">
            <template slot-scope="scope">
              <el-image
                v-if="scope.row.verify_type === 1"
                style="width: 100px; height: 100px;"
                fit="contain"
                :src="
                  scope.row.activate_code +
                  $common.K_QINIU_IMAGE_SMALL_END_200
                "
                :preview-src-list="[scope.row.activate_code]"
                lazy
              ></el-image>
              <p style="font-weight: bold" v-else>{{ scope.row.activate_code }}</p>
            </template>
          </el-table-column>
          <el-table-column label="中奖时间">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ $common.formatTimesToTime(scope.row.created_at) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="活动类型">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                lotteryMap.get(scope.row.activity_id)
                ? lotteryMap.get(scope.row.activity_id).name
                : "无"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="是否导单">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.is_import_doc ? "已导单" : "未导单" }}</p>
            </template>
          </el-table-column>
          <el-table-column label="中奖等级">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="prize_list[scope.row.prize].tag"
                effect="dark"
              >{{ prize_list[scope.row.prize].name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="status_list.find((item)=>scope.row.status===item.id).tag"
                effect="dark"
              >{{ status_list.find((item)=>scope.row.status===item.id).name }}</el-tag>
            </template>
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope" v-if="scope.row.contact_info_json||scope.row.customer.id">
              <el-button
                @click.native="show_customer_info(scope.row)"
                style="color: #03aef9"
                type="text"
              >抽奖人详情</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-dialog title="用户信息" :visible.sync="customerInfoDialogVisible" width="40%">
        <ul class="customer-info" v-if="customerInfo.id" style="text-align:left">
          <li>
            <span>用户邮箱</span>：
            <span>{{ customerInfo.email || "-" }}</span>
          </li>
          <li>
            <span>中奖人姓名</span>：
            <span>{{ customerInfo.name || "-" }}</span>
          </li>
          <li>
            <span>中奖人电话</span>：
            <span>{{ customerInfo.tel || "-" }}</span>
          </li>
          <li>
            <span>中奖人地址</span>：
            <span>{{ customerInfo.address || "-" }}</span>
          </li>
        </ul>
        <ul class="customer-info" v-else>
          <li v-for="(value,key) in customerInfo" :key="'customer'+key">
            <span>{{ key }}</span>：
            <span>{{ value }}</span>
          </li>
        </ul>
        <span slot="footer" class="dialog-footer">
          <el-button @click="customerInfoDialogVisible = false">取 消</el-button>
          <el-button type="primary" @click="customerInfoDialogVisible = false">确 定</el-button>
        </span>
      </el-dialog>
      <el-footer class="footer-info">
        <el-button type="primary" @click="export_excel" size="medium" style="margin: 10px">导出excel</el-button>
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes,prev, pager, next, jumper"
          :page-sizes="[20, 30, 50, 100]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "LotteryRecordList",
  data() {
    return {
      loading: false,
      search_key: "",
      status: 0,
      export_status: 0,
      id: "",
      lottery_name: "",
      prize_list: [
        {
          id: 0,
          name: "未中奖",
          tag: ""
        },
        {
          id: 1,
          name: "一等奖",
          tag: "success"
        },
        {
          id: 2,
          name: "二等奖",
          tag: "success"
        },
        {
          id: 3,
          name: "三等奖",
          tag: "success"
        },
        {
          id: 4,
          name: "四等奖",
          tag: "success"
        }
      ],
      status_list: [
        {
          id: 0,
          name: "所有",
          tag: ""
        },
        {
          id: 1000,
          name: "已抽奖",
          tag: "warning"
        },
        {
          id: 2000,
          name: "已抽奖,填写信息",
          tag: "success"
        },
        {
          id: 3000,
          name: "已发货",
          tag: "primary"
        },
        {
          id: 4000,
          name: "失效",
          tag: "info"
        }
      ],
      import_doc_status_list: [
        {
          id: 0,
          name: "所有",
          tag: ""
        },
        {
          id: 1,
          name: "已导单",
          tag: "warning"
        },
        {
          id: 2,
          name: "未导单",
          tag: "success"
        }
      ],
      page_total: 0,
      page_size: 20,
      page_number: 1,
      recordList: [],
      lotteryMap: {},
      lotteryList: [],
      begin_time: "",
      end_time: "",
      customerInfoDialogVisible: false,
      customerInfo: {},
      prize: -1
    };
  },
  created() {
    if (this.$route.query.lottery_id) {
      this.id = this.$route.query.lottery_id;
    }
    this.lottery_name = this.$route.query.name;
    this.lotteryMap = new Map();
    this.getLotteryList();
    this.getLotteryRecordList();
  },
  methods: {
    getLotteryList() {
      let _me = this;
      let key = "lotteryList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.lotteryList = val;
        for (let i of _me.lotteryList) {
          _me.lotteryMap.set(i.id, i);
        }
        return;
      }

      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/admin/find/list")
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.lotteryList =
              res.data.array === undefined ? [] : res.data.array;
            for (let i of _me.lotteryList) {
              _me.lotteryMap.set(i.id, i);
            }
            _me.$common.setGlobalStorage(key, _me.lotteryList);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    search() {
      this.page_number = 1;
      this.getLotteryRecordList();
    },
    handleCurrentChange(val) {
      this.page_number = val;
      this.getLotteryRecordList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getLotteryRecordList();
    },
    getLotteryRecordList() {
      let _me = this;

      let params = {
        key: _me.search_key,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
        status: _me.status,
        prize: _me.prize,
        import_doc_status: _me.export_status,
        activity_id: _me.id,
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time)
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/lottery/record/admin/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.recordList = res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    show_customer_info(lotteryInfo) {
      if (lotteryInfo.contact_info_json === "") {
        this.customerInfo = lotteryInfo.customer;
        this.customerInfo.address = lotteryInfo.address
          ? lotteryInfo.address
          : "";
      } else {
        this.customerInfo = JSON.parse(lotteryInfo.contact_info_json);
      }
      this.customerInfoDialogVisible = true;
    },
    export_excel() {
      let _me = this;
      let params = {
        key: _me.search_key,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "",
        status: _me.status,
        import_doc_status: _me.export_status,
        activity_id: _me.id,
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time)
      };
      _me.loading = true;

      _me.$common
        .httpPostBlob("/api/activity/lottery/record/admin/download", params)
        .then(res => {
          let content_disposition = decodeURI(
            res.headers["content-disposition"]
          ); //解码后获取文件名字
          content_disposition = content_disposition.substring(
            content_disposition.indexOf("utf-8") + 7
          );
          res = res.data;
          _me.loading = false;
          if (res && res.size > 0) {
            const blob = new Blob([res]);
            // const fileName = "刮刮码.xlsx"; //下载文件名称
            const elink = document.createElement("a");
            elink.download = content_disposition;
            elink.style.display = "none";
            elink.href = URL.createObjectURL(blob);
            document.body.appendChild(elink);
            elink.click();
            URL.revokeObjectURL(elink.href); // 释放URL 对象
            _me.getLotteryRecordList();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    }
  }
};
</script>

<style scoped>
.head-info >>> .el-form-item__content {
  width: 180px;
}

.head-info >>> .el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 100%;
}

.head-info >>> .el-input--suffix .el-input__inner {
  padding-right: 0;
}

.footer-info {
  display: flex;
  justify-content: space-between;
  height: 60px;
  text-align: right;
  background: #f1f1f1;
}

.customer-info {
  text-align: left;
  word-break: break-word;
}

.customer-info li {
  line-height: 2;
}

.customer-info li > span:nth-child(2) {
  color: red;
}

.customer-info li span {
  font-size: 18px;
}

li {
  list-style: none;
}
</style>
