<template>
  <div v-loading="loading" class="main-frame">
    <!-- 二维码 -->
    <div class="qr_code">
      <div id="qrCode" ref="qrCodeDiv" class="qrCode"></div>
      <a :href="this.$common.preview_domain + '/lottery/number'">
        {{ qr_code_url }}
      </a>
    </div>
    <div>
      <h2 class="number_lottery_title">LUCKY DRAW</h2>
      <h4>ROUND {{ round_number }}</h4>

      <h3>Total number {{ pool_number }}</h3>

      <div class="polling">
        <span>real-time </span>
        <el-switch
          v-model="is_polling"
          active-color="#13ce66"
          inactive-color="#ff4949"
          @change="polling(is_polling)"
        >
        </el-switch>
      </div>
      <div class="lottery">
        <div class="lottery_box" v-if="active_index == 'pool'">
          <!-- 抽奖池 -->
          <vue-seamless-scroll
            :data="member_list"
            :class-option="optionLeft"
            class="seamless-warp2"
          >
            <ul class="seamless_list">
              <li class="seamless_item" v-for="(item, i) in member_list" :key="i">
                {{ item }}
              </li>
            </ul>
          </vue-seamless-scroll>
        </div>
        <!-- 抽奖页面 -->
        <div class="lottery_page" v-if="active_index == 'page'">
          <div class="lottery_page_box">
            <vue-seamless-scroll
              v-if="!visible"
              :data="member_list"
              class="seamless-warp"
              :class-option="classOption()"
            >
              <ul class="nostyle">
                <li
                  class="nostyle"
                  v-for="(item, index) in this.member_list"
                  :key="index"
                >
                  {{ item }}
                </li>
              </ul>
            </vue-seamless-scroll>
            <!-- <ul class="nostyle">
      <li class="nostyle" v-show="visible">{{ this.win }}</li>
    </ul> -->
            <div class="nostyle" v-show="visible">
              {{ this.win }}
            </div>

            <!-- <el-button @click="animationStart()">抽取</el-button> -->
          </div>

          <div class="lottery_page_result">
            <div class="page_box">
              <div class="page_item" v-for="(item, i) in win_list" :key="i">
                {{ item }}
              </div>
            </div>
          </div>
        </div>
        <!-- 抽奖结果 -->
        <div class="lottery_result" v-if="active_index == 'result'">
          <div v-if="win_round && win_round.length > 0">
            <div class="result_round" v-for="(e, idx) in win_round" :key="idx">
              <div class="result_title">ROUND {{ e.round }}</div>
              <div class="result_list">
                <div
                  class="result_item"
                  v-for="(e_list, idx_list) in e.drawn_member_list"
                  :key="idx_list"
                >
                  {{ e_list.number }}
                </div>
              </div>
            </div>
          </div>
          <div v-else>No data</div>
        </div>
        <div class="lottery_buttons">
          <div
            class="lottery_button"
            style="backgroundColor: #06c5d8"
            @click="addNewRound()"
          >
            new round
          </div>
          <div
            class="lottery_button"
            style="backgroundColor: #06c5d8"
            @click="addNumberLottery()"
          >
            clear
          </div>
          <div
            class="lottery_button"
            :style="{ backgroundColor: is_start ? '#cd8a17' : '#1E1FEA' }"
            @click="startLotteryStart"
            v-if="is_start"
          >
            START
          </div>
          <el-button
            v-else
            class="lottery_button"
            :style="{ backgroundColor: is_start ? '#cd8a17' : '#1E1FEA' }"
            @click="startLotteryWin"
            :disabled="is_lottery ? false : true"
          >
            WIN
          </el-button>
          <!-- #1E1FEA -->
          <div
            class="lottery_button"
            style="backgroundColor: #06c5d8"
            v-if="isRemain"
            @click="isRemain = false"
          >
            REMAIN
          </div>

          <el-input
            v-else
            class="lottery_button"
            v-model="remain"
            placeholder="REMAIN"
          ></el-input>

          <div
            class="lottery_button"
            style="backgroundColor: #06c5d8"
            @click="showResult"
          >
            result
          </div>
        </div>
        <div v-if="!is_lottery" style="color: #a0a0a0">Drawing</div>
      </div>
    </div>
    <i @click="fullScreen" class="el-icon-full-screen"></i>
  </div>
</template>

<script>
const step = 10;
import vueSeamlessScroll from "vue-seamless-scroll";
import QRCode from "qrcodejs2";
export default {
  name: "LotteryListView",
  components: { vueSeamlessScroll },
  data() {
    return {
      loading: false,
      active_index: "pool",
      pool_number: "",
      member_list: [],
      number_lottery_id: "",
      remain: "",
      isRemain: true,
      start_text: "START",
      is_start: true,
      round_number: 0,
      round_id: "",

      win: "",
      visible: false,
      step: step,
      userInfo: {
        userID: undefined,
        userName: undefined,
      },

      result_text: "",
      win_list: [],
      draw_list: [],
      win_round: [],

      remainTimer: null,
      timer: null,
      is_lottery: true,
      is_polling: false,
      member_list_timer: null,
      qr_code_url: "",
    };
  },
  mounted() {
    this.getNumberLotteryActive();
    this.$nextTick(function () {
      this.bindQRCode();
    });

    if (this.$common.preview_domain.indexOf("http://") > -1) {
      this.qr_code_url =
        this.$common.preview_domain.replace("http://", "") + "/lottery/number";
    }
    if (this.$common.preview_domain.indexOf("https://") > -1) {
      this.qr_code_url =
        this.$common.preview_domain.replace("https://", "") + "/lottery/number";
    }

    // 页面开启定时器，离开页面销毁定时器
    this.polling(true);
  },
  computed: {
    optionLeft() {
      return {
        direction: 2,
        limitMoveNum: 2,
        step: 10,
        // autoPlay: false,
      };
    },
  },
  destroyed() {
    clearInterval(this.member_list_timer);
  },
  methods: {
    polling(value) {
      this.is_polling = value;
      if (this.is_polling) {
        this.member_list_timer = setInterval(() => {
          this.getPoolNumber(this.number_lottery_id);
        }, 10000);
      } else {
        clearInterval(this.member_list_timer);
      }
    },

    bindQRCode: function () {
      let url = this.$common.preview_domain + "/lottery/number";
      new QRCode(this.$refs.qrCodeDiv, {
        text: url,
        width: 200,
        height: 200,
        colorDark: "#333333", //二维码颜色
        colorLight: "#ffffff", //二维码背景色
        correctLevel: QRCode.CorrectLevel.L, //容错率，L/M/H
      });
    },
    // 获取当前最活跃的活动
    getNumberLotteryActive() {
      let _me = this;

      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/number/lottery/admin/find/active")
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            //   获取抽奖池的数据和抽奖结果数据
            let member_array = [];

            if (res.data.member_list && res.data.member_list.length > 0) {
              for (let i of res.data.member_list) {
                member_array.push(i.number.toString());
              }
            }
            _me.member_list = member_array;

            let result_array = [];
            let win_array = [];
            let win_array_length = res.data.round_list.length - 1;

            if (res.data.round_list && res.data.round_list.length > 0) {
              for (let i of res.data.round_list) {
                result_array.push(i);
              }

              // 将最近轮次的数据进行赋值
              if (
                res.data.round_list[win_array_length].drawn_member_list &&
                res.data.round_list[win_array_length].drawn_member_list.length > 0
              ) {
                for (let i of res.data.round_list[win_array_length].drawn_member_list) {
                  win_array.push(i.number);
                }
              }
              _me.remain =
                res.data.round_list[win_array_length].remain > 0
                  ? res.data.round_list[win_array_length].remain
                  : "";
              // 在当前活跃的活动中，获取最新的活动轮次
              _me.round_number = res.data.round_list[win_array_length].round;
              _me.round_id = res.data.round_list[win_array_length].id;
              _me.win_list = win_array;
            }

            _me.win_round = result_array;

            // 当前活动有存在轮次，就用轮次，没有就是0
            _me.round_number =
              res.data.round_list && res.data.round_list.length > 0
                ? _me.round_number
                : 0;

            // 记录当前活跃活动id
            _me.number_lottery_id = res.data.id ? res.data.id : "";

            if (!!_me.remain) {
              _me.isRemain = false;
            }
            _me.getPoolNumber(res.data.id);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err + "---" || "获取数据失败",
            type: "error",
          });
        });
    },
    // 查询当前号码抽奖活动未中奖的参与人员列表
    getPoolNumber(id) {
      let _me = this;

      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/number/lottery/admin/member/find/un/drawn/list", {
          number_lottery_id: id,
        })
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.pool_number = res.data && res.data.length > 0 ? res.data.length : 0;

            let member_array = [];

            if (res.data && res.data.length > 0) {
              for (let i of res.data) {
                member_array.push(i.number.toString());
              }
            }
            _me.member_list = member_array;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err + "---" || "获取数据失败",
            type: "error",
          });
        });
    },
    // 添加一个活动轮次
    addNewRound() {
      let _me = this;

      _me.is_start = true;
      _me.active_index = "pool";
      _me.win_list = [];

      let params = {
        number_lottery_id: _me.number_lottery_id,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/number/lottery/admin/round/add", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            //   查找当前活跃的那个活动

            _me.round_number = res.data.round > 0 ? res.data.round : 0;
            _me.round_id = res.data.id;
            _me.remain = res.data.remain > 0 ? res.data.remain : "";
            _me.win_list =
              res.data.drawn_member_list && res.data.drawn_member_list.length > 0
                ? res.data.drawn_member_list
                : [];
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    // 添加一个号码抽奖活动
    addNumberLottery() {
      let _me = this;
      _me.is_start = true;

      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/number/lottery/admin/add")
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            //   查找当前活跃的那个活动
            _me.getNumberLotteryActive();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
        });
    },
    // 进入抽奖页面
    startLotteryStart() {
      if (this.member_list.length === 0) {
        this.$message.success("抽奖池暂无人员参与！");
      } else {
        this.is_start = false;
        this.active_index = "page";
      }
    },
    // 开始抽奖
    startLotteryWin() {
      //   判断remain进行抽奖或者进入下一个轮次
      if (this.remain > 0) {
        this.generateRandomNum(this.remain);
      } else {
        this.isRemain = true;
        this.$message.error("请继续填写REMAIN进行抽奖");
      }
    },

    classOption() {
      return {
        step: this.step, // 数值越大速度滚动越快
        limitMoveNum: this.member_list.length, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: false, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
    // 动画开始
    animationStart(time) {
      this.visible = false;
      this.step = 100;

      //   动画滚动三秒
      setTimeout(() => {
        this.animationEnd(time);
      }, time * 0.5);
    },
    // 动画结束
    animationEnd(time) {
      this.step = 0;
      this.visible = true;

      this.win = this.draw_list[this.draw_list.length - this.remain];
      this.win_list.push(this.win);
      // 去除当前抽中的
      for (let i = 0; i < this.member_list.length; i++) {
        if (this.win == this.member_list[i]) {
          this.member_list.splice(i, 1);
        }
      }

      this.remain = this.remain - 1;
      if (this.remain > 0) {
        setTimeout(() => {
          this.animationStart(time);
        }, time * 0.5);
      } else {
        setTimeout(() => {
          this.visible = false;
          // 初始化滚动速度
          this.step = step;
          this.getPoolNumber(this.number_lottery_id);
        }, 3000);
        this.is_lottery = true;
      }
    },
    generateRandomNum(number) {
      let _me = this;
      _me.is_lottery = false;

      let params = {
        id: _me.number_lottery_id,
        round_id: _me.round_id,
        count: number,
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/number/lottery/admin/start/draw", params)
        .then((res) => {
          _me.loading = false;
          if (res.code === "1000000") {
            this.draw_list = res.data.numbers;

            let time = (res.data.animation_time / res.data.numbers.length) * 1000;

            // 开始动画
            this.animationStart(time);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error",
            });
            this.is_lottery = true;
          }
        })
        .catch((err) => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error",
          });
          this.is_lottery = true;
        });
    },

    showResult() {
      this.is_start = true;
      this.active_index = "result";
      this.getNumberLotteryActive();
    },
    fullScreen() {
      let header = document.getElementsByTagName("header");
      let aside = document.getElementsByTagName("aside");
      if (header[0].style.height == "60px") {
        header[0].style.height = "0";
      } else {
        header[0].style.height = "60px";
      }
      if (aside[0].style.width == "200px") {
        aside[0].style.width = "0";
      } else {
        aside[0].style.width = "200px";
      }
    },
  },
};
</script>

<style scoped>
.main-frame {
  position: relative;
}

.qr_code {
  position: absolute;
  top: 50px;
  left: 20px;
}

.el-icon-full-screen {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.qr_code a {
  width: 100%;
  font-size: 30px;
}

.qrCode {
  width: 100%;
  height: 250px;
  display: flex;
  margin-top: 10px;
  margin-left: 80px;
  margin-bottom: 20px;
}

.number_lottery_title {
  font-size: 40px;
  color: #026937;
}

.polling {
  width: 1000px;
  text-align: right;
  margin: 0px auto;
}

.lottery {
  margin-left: 100px;
}

.lottery_box,
.lottery_page,
.lottery_result {
  height: 500px;
  width: 1000px;
  overflow: hidden;
  margin: 0px auto;
}

.seamless-warp2 {
  overflow: hidden;
  width: 1000px;
  margin: 200px auto;
}

.item li {
  float: left;
  width: 200px;
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  font-size: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #727272;
  font-weight: bold;
}

.seamless_list {
  display: flex;
  width: 1000px;
}

.seamless_item {
  flex: 1;
  min-width: 200px;
  border-radius: 15px;
  text-align: center;
  display: inline-block;
  font-size: 80px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: #727272;
  font-weight: bold;
}

.lottery_buttons {
  width: 1000px;
  margin: 0px auto;
  display: flex;
  justify-content: space-between;
}

.lottery_button {
  width: 200px;
  font-size: 30px;
  color: #fff;
  font-weight: bold;
  height: 60px;
  line-height: 60px;
  text-align: center;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 50px;
  padding: 0;
}

.lottery_button >>> input {
  width: 100%;
  height: 60px;
  color: #fff;
  font-weight: bold;
  background-color: #06c5d8;
  text-align: center;
}

ul {
  padding: 0;
  margin: 0;
}

.lottery_button >>> .el-input__inner::-webkit-input-placeholder {
  color: #fff !important;
  text-align: center;
  font-size: 30px;
}
.lottery_button >>> .el-input__inner::-moz-input-placeholder {
  color: #fff !important;
  text-align: center;
  font-size: 30px;
}
.lottery_button >>> .el-input__inner::-ms-input-placeholder {
  color: #fff !important;
  text-align: center;
  font-size: 30px;
}

.openNumber {
  display: inline-block;
  width: 30px;
  height: 30px;
  padding-right: 20px;
}
.num {
  width: 30px;
  height: 30px;
  overflow: hidden;
}
.num .span {
  color: #fff;
  width: 30px;
  height: 30px;
  font-weight: bold;
  float: left;
}
.num .span .span div {
  text-align: center;
}
.down-up-translate-fade-enter-active,
.down-up-translate-fade-leave-active {
  transition: all 0.1s;
  -webkit-transition: all 0.1s;
  -moz-transition: all 0.1s;
  -o-transition: all 0.1s;
}
.down-up-translate-fade-enter,
.down-up-translate-fade-leave-active {
  opacity: 1;
}
.down-up-translate-fade-leave-active {
  transform: translateY(-50px);
  -webkit-transform: translateY(-50px);
  -moz-transform: translateY(-50px);
  -o-transform: translateY(-50px);
}
.value {
  /* background: url('../../lib/img/guoqing.png') no-repeat top center; */
  /* background-size: 100% 100%; */
  background-color: #000;
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 22px;
  font-weight: bold;
}

.lottery_page_box {
  margin: 200px auto;
}

.seamless-warp {
  width: 100%;
  height: 80px;
  overflow: hidden;
  transition: all 0.5s;
  font-weight: bold;
}

.nostyle {
  list-style: none;
  padding: 0px;
  margin: 0px;
  font-size: 100px;
  transition: all 0.5s;
  font-weight: bold;
}

.lottery_page {
  position: relative;
}

.lottery_page_result {
  position: absolute;
  right: 30px;
  top: 60px;
}

.page_box {
  width: 250px;
  height: 400px;
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  overflow-y: auto;
}

.page_item {
  font-size: 60px;
  font-weight: bold;
  color: red;
}

/* result */
.lottery_result {
  overflow-y: auto;
  text-align: left;
  font-size: 36px;
  font-weight: bold;
  /* margin-bottom: 50px; */
}

.result_round {
  margin-bottom: 40px;
}

/* .result_title {
    font-size: 36px;
} */

.result_list {
  display: flex;
  flex-wrap: wrap;
}

.result_item {
  color: red;
  margin-right: 20px;
  margin-top: 10px;
}

/* 修改浏览器默认滚动条样式 */
.main-frame {
  scrollbar-arrow-color: transparent !important; /*上下按钮上三角箭头的颜色*/
  scrollbar-face-color: transparent !important; /*滚动条凸出部分的颜色*/
  scrollbar-3dlight-color: transparent !important; /*滚动条亮边的颜色*/
  scrollbar-highlight-color: transparent !important; /*滚动条空白部分的颜色*/
  scrollbar-shadow-color: transparent !important; /*滚动条阴影的颜色*/
  scrollbar-darkshadow-color: transparent !important; /*滚动条强阴影的颜色*/
  scrollbar-track-color: transparent !important; /*滚动条背景颜色*/

  scrollbar-base-color: transparent !important; /*滚动条的基本颜色*/
  /* Cursor:url(mouse.cur);  */
  cursor: pointer;
  /* 自定义个性鼠标 */
  /*以上2项适用与：body、div、textarea、iframe*/
}

::-webkit-scrollbar {
  /* 滚动条整体部分 */
  width: 10px;
  margin-right: 2px;
}
/* 滚动条两端的按钮样式 start */
::-webkit-scrollbar-button {
  display: block;
}
::-webkit-scrollbar-button:horizontal:single-button:start {
  width: 14px;
  background-color: red;
  cursor: pointer;
}
::-webkit-scrollbar-button:horizontal:single-button:end {
  width: 14px;
  /* background: url(../img/scroll-bottom.png) no-repeat; */
  background-color: red;
  cursor: pointer;
}
::-webkit-scrollbar-button:vertical:single-button:start {
  width: 10px;
  cursor: pointer;
  /* width: 0; */
  /* height: 0; */
  border-style: solid;
  border-width: 0 5px 10px 5px;
  border-color: transparent transparent #e5e6e7 transparent;
}
::-webkit-scrollbar-button:vertical:single-button:end {
  width: 10px;
  /* width: 0; */
  /* height: 0; */
  border-style: solid;
  border-width: 10px 5px 0 5px;
  border-color: #e5e6e7 transparent transparent transparent;
  cursor: pointer;
}
/* 滚动条两端的按钮 end */
::-webkit-scrollbar:horizontal {
  height: 10px;
  /* margin-bottom:2px */
}
::-webkit-scrollbar-track {
  /* 外层轨道 */
  border-radius: 10px;
}
::-webkit-scrollbar-track-piece {
  /*内层轨道，滚动条中间部分 */
  background-color: transparent !important;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  /* 滑块 */
  width: 14px;
  height: 10px;
  border-radius: 5px;
  background: #e5e6e7 !important;
  position: absolute;
}
::-webkit-scrollbar-corner {
  /* 边角 */
  width: 14px;
  background-color: red;
}
::-webkit-scrollbar-thumb:hover {
  /* 鼠标移入滑块 */
  background: #d6d6d6;
}
</style>
