<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <el-page-header
          class="page_header"
          title="返回"
          @back="
            () => {
              this.$router.go(-1);
            }
          "
        ></el-page-header>
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="活动名称:">
              <el-select v-model="class_id" clearable placeholder="请选择" loading-text="加载中...">
                <el-option key="class-all" label="所有" value></el-option>
                <el-option
                  v-for="item in lotteryList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <!-- <el-form-item label="状态:">
              <el-select
                v-model="page_status"
                clearable
                placeholder="请选择"
                loading-text="加载中..."
              >
                <el-option
                  v-for="item in $common.commonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>-->
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
                @keyup.enter.native="getLotteryPrizeModel"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getLotteryPrizeModel"
            >过滤</el-button>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="newModelOnClick"
            >添加</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="lottery_prize_list"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="奖品名称">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="奖品级别">
            <template slot-scope="scope">
              <!-- <p style="font-weight: bold">{{ scope.row.grade }}</p> -->
              <el-tag
                style="font-weight: bold"
                :type="grade_list[scope.row.grade].tag"
                effect="dark"
              >
                {{
                grade_list[scope.row.grade].name
                ? grade_list[scope.row.grade].name
                : "无"
                }}
              </el-tag>
            </template>
          </el-table-column>
          <el-table-column label="活动名称">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                lotteryMap.get(scope.row.activity_id)
                ? lotteryMap.get(scope.row.activity_id).name
                : "无"
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="PC端图标" width="160">
            <template slot-scope="scope">
              <el-image
                fit="scale-down"
                style="width: 100px; height: 100px"
                :src="scope.row.pc_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                :preview-src-list="[scope.row.pc_image_url]"
                lazy
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="WAP端图标" width="160">
            <template slot-scope="scope">
              <el-image
                fit="scale-down"
                style="width: 100px; height: 100px"
                :src="scope.row.wap_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                :preview-src-list="[scope.row.wap_image_url]"
                lazy
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="权重" width="160">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.number }}</p>
            </template>
          </el-table-column>
          <el-table-column label="奖品剩余数量" width="160">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.remain_count }}</p>
            </template>
          </el-table-column>
          <!-- <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.commonStatusList[scope.row.status].tag"
                effect="dark"
                >{{ $common.commonStatusList[scope.row.status].name }}
              </el-tag>
            </template>
          </el-table-column>-->
          <el-table-column label="操作" width="140">
            <template slot-scope="scope">
              <el-button style="color: #03aef9" @click="editModelOnclick(scope.row)" type="text">编辑</el-button>
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="prev, pager, next, jumper"
          :page-size="page_size"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "LotteryPrizeListView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      grade_list: [
        {
          id: 0,
          name: "谢谢参与",
          tag: ""
        },
        {
          id: 1,
          name: "一等奖",
          tag: "success"
        },
        {
          id: 2,
          name: "二等奖",
          tag: "success"
        },
        {
          id: 3,
          name: "三等奖",
          tag: "success"
        },
        {
          id: 4,
          name: "四等奖",
          tag: "success"
        }
      ],
      page_total: 0,
      page_size: 20,
      page_number: 1,
      lottery_prize_list: [],
      class_id: "",
      lotteryList: [],
      lotteryMap: {}
    };
  },
  created() {
    if (this.$route.query.lottery_id) {
      this.class_id = this.$route.query.lottery_id;
    }
    this.lotteryMap = new Map();
    // this.getLotteryList();
    // this.getLotteryPrizeModel();
  },
  activated() {
    this.getLotteryList();
    this.getLotteryPrizeModel();
  },
  methods: {
    getLotteryList() {
      let _me = this;
      let key = "lotteryList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.lotteryList = val;
        for (let i of _me.lotteryList) {
          _me.lotteryMap.set(i.id, i);
        }
        return;
      }
      let params = {
        page_size: _me.page_size,
        page_number: _me.page_number,
        status: 0
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/admin/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.lotteryList =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
            for (let i of _me.lotteryList) {
              _me.lotteryMap.set(i.id, i);
            }
            _me.$common.setGlobalStorage(key, _me.lotteryList);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    handleCurrentChange(val) {
      this.page_number = val;
      this.getLotteryPrizeModel();
    },
    getLotteryPrizeModel() {
      let _me = this;
      let params = {
        key: _me.search_key,
        page_size: _me.page_size,
        page_number: _me.page_number,
        sort_fields: "-number",
        activity_id: _me.class_id
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/lottery/reward/admin/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.lottery_prize_list =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newModelOnClick() {
      this.$router.push("lottery-prize-detail");
    },
    editModelOnclick(model) {
      this.$router.push("lottery-prize-detail?id=" + model.id);
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id
      };
      _me.$common
        .httpPost("/api/activity/lottery/reward/admin/delete/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.getLotteryPrizeModel();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    }
  }
};
</script>

<style scoped>
</style>
