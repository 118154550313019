export const gradeStatus = {
  0: '未中奖',
  1: '一等奖',
  2: '二等奖',
  3: '三等奖',
  4: '四等奖',
}

export const verifyTypeStatus = {
  1: '凭证',
  2: 'B码',
  3: '随机码',
  4: '查询码',

} 