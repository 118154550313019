<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head" height="60px">
        <div class="head-info">
          <el-form :inline="true" @submit.native.prevent>
            <el-form-item label="开始时间:">
              <el-date-picker
                v-model="begin_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="结束时间:">
              <el-date-picker
                v-model="end_time"
                type="datetime"
                placeholder="选择日期时间"
                default-time="12:00:00"
                align="center"
              ></el-date-picker>
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="page_status" clearable placeholder="请选择" loading-text="加载中...">
                <el-option
                  v-for="item in $common.CommonStatusList"
                  :key="item.id"
                  :label="item.name"
                  :value="item.id"
                ></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="关键字:">
              <el-input
                v-model="search_key"
                clearable
                size="medium"
                placeholder="请输入关键字"
                @keyup.enter.native="getLotteryList"
              ></el-input>
            </el-form-item>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="getLotteryList"
            >过滤</el-button>
            <el-button
              class="top-button"
              size="medium"
              type="primary"
              round
              @click="newModelOnClick"
            >添加</el-button>
          </el-form>
        </div>
      </el-header>
      <el-main>
        <el-table
          :data="lotteryList"
          style="width: 100%"
          :cell-style="$common.tableStyle"
          :header-cell-style="$common.tableStyle"
          border
          default-expand-all
        >
          <el-table-column label="#" width="80">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.$index + 1 }}</p>
            </template>
          </el-table-column>
          <el-table-column label="活动名称">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.name }}</p>
            </template>
          </el-table-column>
          <el-table-column label="活动地区">
            <template slot-scope="scope">
              <p style="font-weight: bold">
                {{
                countriesMap.get(scope.row.country_id)
                ? countriesMap.get(scope.row.country_id).chinese_short_name
                : " "
                }}
              </p>
            </template>
          </el-table-column>
          <el-table-column label="活动开始时间">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ $common.formatTimesToTime(scope.row.begin) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="活动结束时间">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ $common.formatTimesToTime(scope.row.end) }}</p>
            </template>
          </el-table-column>
          <el-table-column label="奖品列表" width="160">
            <template slot-scope="scope">
              <el-button style="color: #03aef9" @click="getPrizeList(scope.row)" type="text">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="中奖列表" width="160">
            <template slot-scope="scope">
              <el-button style="color: #03aef9" @click="getRecordList(scope.row)" type="text">查看</el-button>
            </template>
          </el-table-column>
          <el-table-column label="PC端图标" width="160">
            <template slot-scope="scope">
              <el-image
                fit="scale-down"
                style="width: 100px; height: 100px"
                :src="scope.row.pc_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                :preview-src-list="[scope.row.pc_image_url]"
                lazy
              ></el-image>
            </template>
          </el-table-column>
          <el-table-column label="WAP端图标" width="160">
            <template slot-scope="scope">
              <el-image
                fit="scale-down"
                style="width: 100px; height: 100px"
                :src="scope.row.wap_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                :preview-src-list="[scope.row.wap_image_url]"
                lazy
              ></el-image>
            </template>
          </el-table-column>
          <!-- <el-table-column label="权重" width="160">
            <template slot-scope="scope">
              <p style="font-weight: bold">{{ scope.row.number }}</p>
            </template>
          </el-table-column>-->
          <el-table-column label="状态" width="120">
            <template slot-scope="scope">
              <el-tag
                style="font-weight: bold"
                :type="$common.CommonStatusList[scope.row.status].tag"
                effect="dark"
              >{{ $common.CommonStatusList[scope.row.status].name }}</el-tag>
            </template>
          </el-table-column>
          <!-- <el-table-column label="活动类型" width="120">
            <template slot-scope="scope">
              {{ scope.row.type }}
            </template>
          </el-table-column>-->
          <el-table-column label="操作" width="140">
            <template slot-scope="scope">
              <el-button style="color: #03aef9" @click="editModelOnclick(scope.row)" type="text">编辑</el-button>
              <el-button
                style="color: #f03c69"
                @click="removeModelOnclick(scope.row)"
                type="text"
              >移除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-main>
      <el-footer class="footer-info">
        <el-pagination
          style="margin-top: 15px"
          background
          layout="sizes, prev, pager, next, jumper"
          :page-sizes="[20, 50, 100, 150]"
          :page-size="page_size"
          @size-change="handleSizeChange"
          :pager-count="9"
          :total="page_total"
          :current-page="page_number"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </el-footer>
    </el-container>
  </div>
</template>

<script>
export default {
  name: "LotteryListView",
  data() {
    return {
      loading: false,
      search_key: "",
      page_status: 0,
      page_total: 0,
      page_size: 15,
      page_number: 1,
      lotteryList: [],
      begin_time: "",
      end_time: "",
      countriesList: "",
      countriesMap: {}
    };
  },
  created() {
    this.countriesMap = new Map();
    this.getCountries();
    this.getLotteryList();
  },
  methods: {
    getCountries() {
      let _me = this;
      let key = "countriesList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.countriesList = val;
        for (let i of _me.countriesList) {
          _me.countriesMap.set(i.id.toString(), i);
        }
        return;
      }

      _me.loading = true;
      _me.$common
        .httpPost("/api/address/admin/country/find/all")
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.countriesList = res.data;
            for (let i of _me.countriesList) {
              _me.countriesMap.set(i.id.toString(), i);
            }
            _me.$common.setGlobalStorage(key, _me.countriesList);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    handleCurrentChange(val) {
      this.page_number = val;
      this.getLotteryList();
    },
    handleSizeChange(val) {
      this.page_size = val;
      this.getLotteryList();
    },
    getLotteryList() {
      let _me = this;

      let params = {
        key: _me.search_key,
        status: _me.page_status,
        page_size: _me.page_size,
        page_number: _me.page_number,
        // 1.获取最新，2.获取最旧
        page_flag: "",
        sort_fields: "",
        begin_time: _me.$common.formatTimestampLongZone(_me.begin_time),
        end_time: _me.$common.formatTimestampLongZone(_me.end_time)
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/admin/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.lotteryList =
              res.data.array === undefined ? [] : res.data.array;
            _me.page_total = res.data.total;
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newModelOnClick() {
      this.$router.push("lottery-detail");
    },
    editModelOnclick(model) {
      this.$router.push("lottery-detail?id=" + model.id);
    },
    removeModelOnclick(model) {
      let _me = this;
      _me
        .$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning"
        })
        .then(() => {
          _me.removeItem(model);
        })
        .catch(() => {
          // eslint-disable-next-line no-console
          console.log("取消删除");
        });
    },
    removeItem(model) {
      let _me = this;
      _me.loading = true;
      let params = {
        id: model.id
      };
      _me.$common
        .httpPost("/api/activity/admin/delete/one", params)
        .then(res => {
          _me.loading = false;
          _me.getLotteryList();
          if (res.code === "1000000") {
            _me.$message.success("删除成功");
            _me.$common.removeGlobalStorage("lotteryList");
          } else {
            _me.$message({
              message: res.message || "获取数据失败111",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getPrizeList(model) {
      this.$router.push({
        path: "/lottery-prize",
        query: {
          lottery_id: model.id
        }
      });
    },
    getRecordList(model) {
      this.$router.push({
        path: "/lottery-records",
        query: {
          lottery_id: model.id
        }
      });
    }
  }
};
</script>

<style scoped>
</style>
