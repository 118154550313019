<template>
  <div v-loading="loading" class="main-frame">
    <el-container>
      <el-header class="banner-head">
        <div class="head-info">
          <el-row>
            <el-col style="text-align: left" :span="12">
              <el-page-header title="返回" @back="goBack" content="抽奖活动详情"></el-page-header>
            </el-col>
          </el-row>
        </div>
      </el-header>
      <el-main>
        <el-tabs type="card" v-model="activeTab" :stretch="true" :before-leave="leaveTabOnclick">
          <el-tab-pane label="基本信息" name="0">
            <el-form
              class="edit-form"
              :model="type_model"
              ref="modelForm"
              label-width="160px"
              :rules="rules"
            >
              <el-form-item label="抽奖名称:" prop="name">
                <el-input v-model="type_model.name" clearable></el-input>
              </el-form-item>
              <el-form-item label="别名:" prop="alias">
                <el-input v-model="type_model.alias" clearable></el-input>
              </el-form-item>
              <el-form-item label="抽奖地区:" prop="country_id">
                <el-select v-model="type_model.country_id" filterable placeholder="请选择">
                  <el-option
                    v-for="item in countries_list"
                    :key="item.id"
                    :label="item.chinese_short_name"
                    :value="item.id + ''"
                  ></el-option>
                </el-select>
              </el-form-item>

              <el-form-item label="姓名错误信息:">
                <el-input v-model="err_message.name_err_text" clearable disabled></el-input>
              </el-form-item>
              <el-form-item label="地址错误信息:">
                <el-input v-model="err_message.address_err_text" clearable disabled></el-input>
              </el-form-item>
              <el-form-item label="抽奖码提示信息:">
                <el-input v-model="err_message.lottery_code_err_text" clearable disabled></el-input>
              </el-form-item>
              <el-form-item label="电话错误信息:">
                <el-input v-model="err_message.phone_err_text" clearable disabled></el-input>
              </el-form-item>
              <el-form-item label="状态:">
                <el-select v-model="type_model.status" placeholder="请选择" loading-text="加载中...">
                  <el-option
                    v-for="item in $common.StatusSelectList"
                    :key="item.id"
                    :label="item.name"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </el-form-item>
              <!-- <el-form-item label="排序:">
            <el-input-number
              v-model="type_model.number"
              :min="0"
              label="排序"
            ></el-input-number>
              </el-form-item>-->
              <el-form-item style="text-align: left" label="PC端图标:">
                <el-input v-model="type_model.pc_image_url" clearable></el-input>
                <el-row>
                  <el-col :span="6">
                    <el-image
                      fit="contain"
                      style="width: 100px; height: 100px; margin-top: 10px"
                      :src="type_model.pc_image_url + $common.K_QINIU_IMAGE_SMALL_END_200"
                      :preview-src-list="[type_model.pc_image_url]"
                      lazy
                    ></el-image>
                  </el-col>
                  <el-col :span="18">
                    <dl>
                      <dt class="edit-row-dt">
                        <el-button
                          size="mini"
                          type="warning"
                          @click="getImageByWeb('pc_image_url')"
                        >资源选择</el-button>
                      </dt>
                    </dl>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item style="text-align: left" label="WAP端图标:">
                <el-input v-model="type_model.wap_image_url" clearable></el-input>
                <el-row>
                  <el-col :span="6">
                    <el-image
                      fit="contain"
                      style="width: 100px; height: 100px; margin-top: 10px"
                      :src="
                        type_model.wap_image_url + $common.K_QINIU_IMAGE_SMALL_END_200
                      "
                      :preview-src-list="[type_model.wap_image_url]"
                      lazy
                    ></el-image>
                  </el-col>
                  <el-col :span="18">
                    <dl>
                      <dt class="edit-row-dt">
                        <el-button
                          size="mini"
                          type="warning"
                          @click="getImageByWeb('wap_image_url')"
                        >资源选择</el-button>
                      </dt>
                    </dl>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item style="text-align: left" label="PC端宣传图片:">
                <el-input v-model="type_model.pc_promotional_image_url" clearable></el-input>
                <el-row>
                  <el-col :span="6">
                    <el-image
                      fit="contain"
                      style="width: 100px; height: 100px; margin-top: 10px"
                      :src="
                        type_model.pc_promotional_image_url +
                        $common.K_QINIU_IMAGE_SMALL_END_200
                      "
                      :preview-src-list="[type_model.pc_promotional_image_url]"
                      lazy
                    ></el-image>
                  </el-col>
                  <el-col :span="18">
                    <dl>
                      <dt class="edit-row-dt">
                        <el-button
                          size="mini"
                          type="warning"
                          @click="getImageByWeb('pc_promotional_image_url')"
                        >资源选择</el-button>
                      </dt>
                    </dl>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item style="text-align: left" label="WAP端宣传图片:">
                <el-input v-model="type_model.wap_promotional_image_url" clearable></el-input>
                <el-row>
                  <el-col :span="6">
                    <el-image
                      fit="contain"
                      style="width: 100px; height: 100px; margin-top: 10px"
                      :src="
                        type_model.wap_promotional_image_url +
                        $common.K_QINIU_IMAGE_SMALL_END_200
                      "
                      :preview-src-list="[type_model.wap_promotional_image_url]"
                      lazy
                    ></el-image>
                  </el-col>
                  <el-col :span="18">
                    <dl>
                      <dt class="edit-row-dt">
                        <el-button
                          size="mini"
                          type="warning"
                          @click="getImageByWeb('wap_promotional_image_url')"
                        >资源选择</el-button>
                      </dt>
                    </dl>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item label="宣传图片跳转内容:" prop="promotional_jump_url">
                <el-input v-model="type_model.promotional_jump_url" clearable></el-input>
              </el-form-item>

              <el-form-item label="活动结束提示内容:" prop="end_text">
                <!-- <el-input v-model="type_model.end_text" clearable></el-input> -->
                <el-input
                  v-model="type_model.end_text"
                  type="textarea"
                  :autosize="{ minRows: 12, maxRows: 8 }"
                  clearable
                ></el-input>
              </el-form-item>
              <el-form-item label="活动结束提示跳转链接:" prop="promotional_jump_url">
                <el-input v-model="type_model.end_jump_url" clearable></el-input>
              </el-form-item>
              <el-form-item style="text-align: left" label="PC端活动结束图片:">
                <el-input v-model="type_model.pc_end_image_url" clearable></el-input>
                <el-row>
                  <el-col :span="6">
                    <el-image
                      fit="contain"
                      style="width: 100px; height: 100px; margin-top: 10px"
                      :src="
                        type_model.pc_end_image_url + $common.K_QINIU_IMAGE_SMALL_END_200
                      "
                      :preview-src-list="[type_model.pc_end_image_url]"
                      lazy
                    ></el-image>
                  </el-col>
                  <el-col :span="18">
                    <dl>
                      <dt class="edit-row-dt">
                        <el-button
                          size="mini"
                          type="warning"
                          @click="getImageByWeb('pc_end_image_url')"
                        >资源选择</el-button>
                      </dt>
                    </dl>
                  </el-col>
                </el-row>
              </el-form-item>
              <el-form-item style="text-align: left" label="WAP端活动结束图片:">
                <el-input v-model="type_model.wap_end_image_url" clearable></el-input>
                <el-row>
                  <el-col :span="6">
                    <el-image
                      fit="contain"
                      style="width: 100px; height: 100px; margin-top: 10px"
                      :src="
                        type_model.wap_end_image_url + $common.K_QINIU_IMAGE_SMALL_END_200
                      "
                      :preview-src-list="[type_model.wap_end_image_url]"
                      lazy
                    ></el-image>
                  </el-col>
                  <el-col :span="18">
                    <dl>
                      <dt class="edit-row-dt">
                        <el-button
                          size="mini"
                          type="warning"
                          @click="getImageByWeb('wap_end_image_url')"
                        >资源选择</el-button>
                      </dt>
                    </dl>
                  </el-col>
                </el-row>
              </el-form-item>

              <el-form-item label="开始时间:">
                <el-date-picker
                  v-model="type_model.begin"
                  type="datetime"
                  placeholder="选择日期时间"
                  default-time="12:00:00"
                  align="center"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="结束时间:">
                <el-date-picker
                  v-model="type_model.end"
                  type="datetime"
                  placeholder="选择日期时间"
                  default-time="12:00:00"
                  align="center"
                ></el-date-picker>
              </el-form-item>
              <el-form-item label="抽奖预览：">
                <a
                  :href="K_PREVIEW_DOMAIN +$common.LotteryPath+ type_model.alias+'?copywriter='+$common.Copywriter
              "
                  target="_blank"
                >
                  <el-button
                    style="width: 100px; height: 50px"
                    type="primary"
                    :disabled="!type_model.id"
                  >预览</el-button>
                </a>
              </el-form-item>
              <el-form-item>
                <el-button
                  style="width: 460px; height: 50px"
                  type="danger"
                  @click="saveTypeModel"
                >保存</el-button>
              </el-form-item>
              <!-- <CopyPaste
                :button_status="this.type_id !== undefined && this.type_id.length > 0"
                @copyInfo="copyInfo(type_model)"
                @pasteInfo="pasteInfo"
                ref="copyPaste"
              ></CopyPaste>-->
            </el-form>
          </el-tab-pane>
          <el-tab-pane label="规则详情" name="1">
            <el-form
              :model="rule_list"
              ref="rule_list"
              label-width="180px"
              style="width: 100%;margin-top: 20px;"
              class="lottery_rule_list_form"
              align="left"
            >
              <el-card class="box-card">
                <!-- <el-form-item label="是否登录:" prop="enable_verify_login">
                  <el-switch
                    v-model="rule_list.enable_verify_login"
                    :active-value="true"
                    :inactive-value="false"
                  ></el-switch>
                </el-form-item>-->
                <el-form-item label="验证类型:" prop="verify_type">
                  <el-select v-model="rule_list.verify_type">
                    <el-option
                      v-for="(v, k) in verifyTypeStatus"
                      :key="k"
                      :value="k * 1"
                      :label="v"
                    ></el-option>
                  </el-select>
                </el-form-item>
                <!-- 批次号列表 -->
                <el-form-item v-if="rule_list.verify_type===3" label="批次号:" prop="batch_no">
                  <el-select
                    multiple
                    placeholder="请选择"
                    collapse-tags
                    v-model="random_code_batch_list"
                  >
                    <el-option v-for="(v, k) in batchList" :key="k" :value="v" :label="v"></el-option>
                  </el-select>
                </el-form-item>
                <!-- <el-form-item label="活动sku:" prop="sku_list">
                <el-tag
                  style="margin-right: 8px;"
                  :key="sku"
                  v-for="sku in rule_list.sku_list"
                  closable
                  :disable-transitions="false"
                  @close="handleRemoveSku(sku)">
                  {{sku}}
                </el-tag>
                <el-input
                  v-if="inputVisible"
                  v-model="inputValue"
                  ref="saveTagInput"
                  size="small"
                  @keyup.enter.native="handleInputConfirm"
                  @blur="handleInputConfirm"
                  style="width:200px;"
                >
                </el-input>
                <el-button v-else class="button-new-tag" size="small" @click="showInput">+ sku</el-button>
                </el-form-item>-->
                <el-form-item
                  v-if="rule_list.enable_verify_login"
                  label="抽奖限制次数:"
                  prop="upper_limit"
                >
                  <el-input-number v-model="rule_list.upper_limit" clearable></el-input-number>
                </el-form-item>
                <el-form-item label="输入框文本:" prop="enter_description">
                  <el-input v-model="rule_list.enter_description" clearable></el-input>
                </el-form-item>
                <el-form-item label="开始文本:" prop="start_text">
                  <el-input v-model="rule_list.start_text" clearable></el-input>
                </el-form-item>
                <el-form-item label="奖项:" prop="rewards">
                  <el-checkbox-group v-model="rewards_choose_list">
                    <el-checkbox
                      v-for="reward in lottery_prize_list"
                      :label="reward"
                      :key="reward.id"
                    >{{ prize_list[reward.grade].name }} {{ reward.name }}</el-checkbox>
                  </el-checkbox-group>
                </el-form-item>
                <el-form-item label="抽奖排序:" prop="reward_sequence">
                  <el-tag
                    :key="i"
                    v-for="(tag, i) in sequence_list"
                    closable
                    :disable-transitions="false"
                    @close="handleClose(tag, i)"
                  >{{ prize_list[tag.grade].name }}</el-tag>

                  <el-dropdown trigger="click" @command="handleCommand">
                    <el-button type="primary">
                      添加奖品项
                      <i class="el-icon-arrow-down el-icon--right"></i>
                    </el-button>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        :command="item"
                        v-for="item in rewards_choose_list"
                        :key="item.id"
                      >{{ prize_list[item.grade].name }}</el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </el-form-item>
              </el-card>

              <!-- <el-form-item>
                <el-button type="primary" @click="updateOrder">更新顺序示意图</el-button>
                <p>抽奖排序示意图：</p>
                <div class="draw_list">
                  <el-col :span="8" :key="i" v-for="(item, i) in sequence_order_list">
                    <div class="grid-content bg-purple">
                      {{ item.name != "抽奖" ? prize_list[item.grade].name : item.name }}
                      {{ i }}
                      {{item.grade}}
                    </div>
                  </el-col>
                </div>
              </el-form-item>-->
              <el-card class="box-card" v-if="rule_list.verify_type === 1">
                <div slot="header" class="header">
                  <span>凭证弹窗</span>
                </div>
                <div>
                  <el-form-item label="弹窗标题文本：">
                    <el-input
                      v-model="rule_list.extend.voucher_title"
                      placeholder="示例：Please upload the receipt and fill in the product quantity（Mavic S9000）."
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="弹窗副标题文本：">
                    <el-input
                      v-model="rule_list.extend.voucher_sub_title"
                      placeholder="示例:（Tip：Receipts will be checked for prize redemption, if inconsistent, the prize will be invalid）"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="弹窗上传按钮文本：">
                    <el-input
                      v-model="rule_list.extend.voucher_upload_text"
                      placeholder="示例:Upload"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="弹窗提交按钮文本：">
                    <el-input
                      v-model="rule_list.extend.voucher_sumbit_text"
                      placeholder="示例:Submit"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="产品数量输入框提示文本：">
                    <el-input
                      v-model="rule_list.extend.voucher_product_quantity_text"
                      placeholder="示例:product quantity"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-card>
              <el-card class="box-card">
                <div slot="header" class="header">
                  <span>中奖结果</span>
                </div>
                <div>
                  <el-form-item label="查看中奖按钮文本：">
                    <el-input v-model="rule_list.extend.view_prizes" placeholder="示例:view prizes"></el-input>
                  </el-form-item>
                  <el-form-item label="未中奖文本:" prop="prize_fail_text">
                    <el-input v-model="rule_list.prize_fail_text" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="中奖文本:" prop="prize_win_text">
                    <el-input v-model="rule_list.prize_win_text" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="中奖提示文本:">
                    <el-input
                      v-model="rule_list.extend.prize_win_tips_text"
                      clearable
                      placeholder="Coupons have been sent to your store card package, please check"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-card>
              <el-card class="box-card">
                <div slot="header" class="header">
                  <span>中奖信息填写</span>
                </div>
                <div>
                  <el-form-item label="确认文本:" prop="confirm_text">
                    <el-input v-model="rule_list.confirm_text" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="修改文本:" prop="edit_text">
                    <el-input v-model="rule_list.edit_text" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="提交文本:" prop="submit_text">
                    <el-input v-model="rule_list.submit_text" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="填完信息文本:" prop="lottery_end_text">
                    <el-input v-model="rule_list.lottery_end_text" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="姓名文本:" prop="name_text">
                    <el-input v-model="rule_list.name_text" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="电话文本:" prop="phone_text">
                    <el-input v-model="rule_list.phone_text" clearable></el-input>
                  </el-form-item>
                  <el-form-item label="地址文本:" prop="address_text">
                    <el-input v-model="rule_list.address_text" clearable></el-input>
                  </el-form-item>
                </div>
              </el-card>
              <el-card class="box-card">
                <div slot="header" class="header">
                  <span>中奖结果记录</span>
                </div>
                <div>
                  <el-form-item label="查看中奖记录按钮文本:">
                    <el-input
                      v-model="rule_list.extend.record_view_text"
                      clearable
                      placeholder="示例：查看中奖记录"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="中奖弹窗标题文本:">
                    <el-input
                      v-model="rule_list.extend.record_title_text"
                      clearable
                      placeholder="示例：中奖记录"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="奖品文本:">
                    <el-input
                      v-model="rule_list.extend.record_reward_text"
                      clearable
                      placeholder="示例：奖品"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="中奖时间文本:">
                    <el-input
                      v-model="rule_list.extend.record_time_text"
                      clearable
                      placeholder="示例：中奖时间"
                    ></el-input>
                  </el-form-item>
                  <el-form-item label="关闭弹窗按钮文本:">
                    <el-input
                      v-model="rule_list.extend.close_text"
                      clearable
                      placeholder="示例：Close"
                    ></el-input>
                  </el-form-item>
                </div>
              </el-card>
              <el-card class="box-card">
                <div slot="header" class="header">
                  <span>规则</span>
                </div>
                <el-form-item label="规则标题:" prop="title">
                  <el-input v-model="rule_list.title" clearable></el-input>
                </el-form-item>
                <el-form-item label="规则:" prop="detail">
                  <!-- 富文本编辑器 -->
                  <div class="content-frame">
                    <div id="rich-text-edit" class="rich-text-edit"></div>
                  </div>
                </el-form-item>
              </el-card>
              <div
                id="lotteryRuleList"
                style="margin-top: 10px;width:100%;display: flex;justify-content: center;"
              >
                <el-form-item>
                  <el-button
                    style="width: 460px; height: 50px"
                    type="danger"
                    @click="saveRuleModel"
                  >保存</el-button>
                </el-form-item>

                <!-- <CopyPaste
                    :button_status="this.type_id !== undefined && this.type_id.length > 0"
                    @copyInfo="copyInfo(rule_list)"
                    @pasteInfo="pasteInfo"
                    ref="copyPaste"
                ></CopyPaste>-->
              </div>
            </el-form>
          </el-tab-pane>
        </el-tabs>
      </el-main>
    </el-container>
    <FileChooseDrawerModule ref="lotteryFileChooseDrawerModule" />
  </div>
</template>

<script>
import FileChooseDrawerModule from "../../file/module/FileChooseDrawerModule";
import CopyPaste from "../../../components/CopyPaste.vue";
// import RuleDetailModule from "./module/ruleDetailModule";
import wangEditor from "wangeditor";
import Hashids from "hashids";
import md5 from "js-md5";
import { verifyTypeStatus } from "../status/index";
import { K_PREVIEW_DOMAIN } from "../../../../public/static/js/config";

export default {
  name: "LotteryDetailView",
  components: {
    CopyPaste,
    FileChooseDrawerModule
    // RuleDetailModule
  },
  data() {
    return {
      K_PREVIEW_DOMAIN,
      activeTab: "0",
      inputVisible: false,
      inputValue: "",
      verifyTypeStatus,
      loading: false,
      type_id: "",
      type_model: {
        id: "",
        name: "",
        region: "",
        alias: "",
        pc_image_url: "",
        wap_image_url: "",
        begin_time: "",
        end_time: "",
        rules: "",
        status: 2,
        type: 1,
        pc_promotional_image_url: "",
        wap_promotional_image_url: "",
        promotional_jump_url: "",
        end_text: "",
        end_jump_url: "",
        wap_end_image_url: "",
        pc_end_image_url: ""
      },
      err_message: "",
      countries_list: [],
      countriesMap: {},
      rule: {},
      prize_list: [
        {
          id: 0,
          name: "未中奖",
          tag: ""
        },
        {
          id: 1,
          name: "一等奖",
          tag: "success"
        },
        {
          id: 2,
          name: "二等奖",
          tag: "warning"
        },
        {
          id: 3,
          name: "三等奖",
          tag: "danger"
        },
        {
          id: 4,
          name: "四等奖",
          tag: "success"
        }
      ],
      rule_list: {
        activity_id: "",
        address_text: "",
        confirm_text: "",
        detail: "",
        edit_text: "",
        enter_description: "",
        lottery_end_text: "",
        name_text: "",
        phone_text: "",
        prize_fail_text: "",
        prize_win_text: "",
        reward_sequence: [],
        rewards: [],
        start_text: "",
        submit_text: "",
        title: "",
        enable_verify_login: false,
        // 1小票2b码3随机码
        verify_type: "",
        // 参与活动的sku
        sku_list: [],
        extend: {
          view_prizes: undefined,
          prize_fail_text: undefined,
          prize_win_text: undefined,
          prize_win_tips_text: undefined,
          voucher_title: undefined,
          voucher_sub_title: undefined,
          voucher_upload_text: undefined,
          voucher_sumbit_text: undefined,
          voucher_product_quantity_text: undefined,
          record_view_text: undefined,
          record_title_text: undefined,
          record_reward_text: undefined,
          record_time_text: undefined,
          close_text: undefined
        }
      },
      rewards_choose_list: [],
      lottery_prize_list: [],
      rewards_list: [],
      sequence_list: [],
      sequence_order_list: [],
      order_list: [],
      activeName: "1",
      rules: {
        alias: [
          { required: true, message: "请输入名称", trigger: "blur" },
          { min: 1, message: "请输入名称", trigger: "blur" }
        ],
        name: [
          { required: true, message: "请输入别名", trigger: "blur" },
          { min: 1, message: "请输入别名", trigger: "blur" }
        ],
        country_id: [
          { required: true, message: "请选择地区", trigger: "change" }
        ],
        batch_no: [{ required: true, message: "请选择批次", trigger: "change" }]
      },
      model_list: [],
      batchList: [],
      random_code_batch_list: []
    };
  },
  async mounted() {
    this.type_id = this.$route.query.id;
    this.countriesMap = new Map();
    this.getCountries();
    try {
      if (this.type_id !== undefined && this.type_id.length > 0) {
        // 查找活动详情
        this.getLotteryTypeModel();
        await this.getLotteryPrizeModel();
        // 查找活动规则详情
        this.getRuleDetailModel();
      } else {
        this.getLotteryPrizeModel();
      }
      this.getModelList();
    } catch (error) {}

    let _me = this;
    const editor = new wangEditor("#rich-text-edit");
    // 配置 onchange 回调函数，将数据同步到 vue 中
    editor.config.onchange = newHtml => {
      _me.rule_list.detail = newHtml;
    };
    // 配置触发 onchange 的时间频率，默认为 200ms
    editor.config.onchangeTimeout = 500; // 修改为 500ms
    // 取消自动 focus(不自动焦点)
    editor.config.focus = false;
    // 自定义 Alert
    editor.config.customAlert = function(s, t) {
      switch (t) {
        case "success":
          _me.$message.success(s);
          break;
        case "info":
          _me.$message.info(s);
          break;
        case "warning":
          _me.$message.warning(s);
          break;
        case "error":
          _me.$message.error(s);
          break;
        default:
          _me.$message.info(s);
          break;
      }
    };
    // 配置字体
    editor.config.fontNames = [
      // 对象形式 v4.6.16
      // 字符串形式
      // "黑体",
      // "仿宋",
      // "楷体",
      // "标楷体",
      // "华文仿宋",
      // "华文楷体",
      // "宋体",
      // "微软雅黑",
      // "Arial",
      // "Tahoma",
      // "Verdana",
      "Times New Roman"
      // "Courier New",
      // "Ubuntu",
    ];
    // 设置编辑区域高度为 500px
    // editor.config.height = 800
    // 配置菜单栏，设置不需要的菜单
    editor.config.excludeMenus = ["head", "emoticon"];
    // 配置上传图片类型
    editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
    // 一次最多上传 1 个图片 - 2021年01月08日14:48:06
    editor.config.uploadImgMaxLength = 1;
    // 配置自定义实现上传图片
    editor.config.customUploadImg = function(resultFiles, insertImgFn) {
      // resultFiles 是 input 中选中的文件列表
      let hashids = new Hashids(_me.product_id);
      for (let i = 0; i < resultFiles.length; i++) {
        _me.loading = true;
        let file = resultFiles[i];
        let file_path = "www/files/v2/article/";
        let file_name_md5 = md5(new Date().getTime() + file.name);
        let file_name = hashids.encodeHex(file_name_md5);
        let file_type = file.type.substring(file.type.lastIndexOf("/") + 1);
        let key = file_path + file_name + "." + file_type; // 文件资源名
        _me.$common.qiniuUploadImage(
          file,
          key,
          function(res) {
            // console.log('已上传大小，单位为字节：' + res.total.loaded)
            // console.log('本次上传的总量控制信息，单位为字节：' + res.total.size)
            // eslint-disable-next-line no-console
            console.log("当前上传进度，范围：0～100：" + res.total.percent);
          },
          function(err, data) {
            // console.log(err, data)
            _me.loading = false;
            if (err == undefined) {
              // insertImgFn 是获取图片 url 后，插入到编辑器的方法
              // 上传图片，返回结果，将图片插入到编辑器中
              // eslint-disable-next-line
              insertImgFn(_me.$common.K_QINIU_DOMAIN + data.key);
            } else {
              _me.$message({
                message: err || "获取数据失败",
                type: "error"
              });
            }
          }
        );
      }
    };

    //配置格式刷
    // const { BtnMenu } = wangEditor;

    // class format extend BtnMenu {
    //   constructor(editor) {
    //     const $elem = wangEditor.$(
    //       `<div class="w-e-menu" data-title="格式刷">
    //     <i class="el-icon-s-open"></i>
    //   </div>`
    //     );
    //     super($elem, editor);
    //   }
    //   for_style(domE) {
    //     let arr = {};
    //     for (let i = 0; i < domE.length; i++) {
    //       arr[domE[i]] = domE[domE[i]];
    //     }
    //     return arr;
    //   }
    //   font_style(domE) {
    //     let arr = [];
    //     Array.from(domE.attributes).forEach((v) => {
    //       arr.push(`${v.name}='${v.value}'`);
    //     });
    //     return arr;
    //   }
    //   string_style(val) {
    //     let arr = "";
    //     for (const key in val) {
    //       if (val.hasOwnProperty(key)) {
    //         const element = val[key];
    //         arr += `${key}:${element};`;
    //       }
    //     }
    //     return arr;
    //   }
    //   format_style(style, text) {
    //     //设置模板
    //     let _html = "";
    //     for (let i = 0; i < style.length; i++) {
    //       if (i == 0) {
    //         if (style[0].html == "font") {
    //           let arr = ``;
    //           for (let index = 0; index < style[0].style.length; index++) {
    //             arr += ` ${style[0].style[index]}`;
    //           }
    //           //   console.log(
    //           //     (_html = `<${style[0].html} ${arr}>${text}</${style[0].html}>`)
    //           //   );
    //           _html = `<${style[0].html} ${arr}>${text}</${style[0].html}>`;
    //         } else {
    //           _html = `<${style[0].html} style="${style[0].style}">${text}</${style[0].html}>`;
    //         }
    //       } else {
    //         if (style[i].html == "font") {
    //           let arr = ``;
    //           for (let index = 0; index < style[i].style.length; index++) {
    //             arr += ` ${style[i].style[index]}`;
    //           }
    //           _html = `<${style[i].html}  ${arr}>${_html}</${style[i].html}>`;
    //         } else {
    //           _html = `<${style[i].html} style="${style[i].style}">${_html}</${style[i].html}>`;
    //         }
    //       }
    //     }
    //     return _html;
    //   }
    //   clickHandler(e, type) {
    //     //console.log(this.$elem);
    //     //console.log("格式刷被点击");
    //     //获取editor全局编辑对象
    //     const editor = this.editor;
    //     // 判断当前格式刷是否已经被激活
    //     // 如果是激活状态：关闭格式刷
    //     if (this.formatShow) {
    //       this.formatShow = false;
    //       editor._brush = false;
    //       // editor._dblBrush = false;
    //       this.$elem.removeClass("w-e-active");
    //       editor.$textContainerElem.removeClass("brush");
    //       //console.log("关闭格式刷");
    //       return;
    //     }
    //     // 如果当前状态是未激活
    //     // 将格式刷改成激活状态
    //     this.formatShow = true;
    //     editor._brush = true;
    //     // this.$elem.addClass('w-e-active')
    //     // 如果是双击格式刷触发连续使用格式刷
    //     // 记录双击格式刷状态
    //     // editor._dblBrush = type === "dblclick" ? true : false;
    //     this.$elem.addClass("w-e-active");
    //     editor.$textContainerElem.addClass("brush");
    //     //获取选中对象
    //     let containerEle = editor.selection.getSelectionContainerElem();
    //     editor._html = [];
    //     if (containerEle.elems[0].tagName.toLowerCase() == "font") {
    //       //console.log("有font");
    //       editor._html.push({
    //         html: containerEle.elems[0].tagName.toLowerCase(),
    //         style: this.font_style(containerEle.elems[0]),
    //       });
    //     } else {
    //       editor._html.push({
    //         html: containerEle.elems[0].tagName.toLowerCase(),
    //         style: this.string_style(this.for_style(containerEle.elems[0].style)),
    //       });
    //     }
    //     //向上整合style
    //     //如果是顶级p标签name将style放在wrap里面，否则直接整合进style
    //     while (!containerEle.equal(editor.$textElem)) {
    //       containerEle = containerEle.parent();
    //       //console.log("循环中", containerEle);
    //       if (
    //         containerEle.parent().equal(editor.$textElem) &&
    //         !containerEle.equal(editor.$textElem)
    //       ) {
    //         editor._html.push({
    //           html: containerEle.elems[0].tagName.toLowerCase(),
    //           style: this.string_style(this.for_style(containerEle.elems[0].style)),
    //         });
    //       }
    //       if (
    //         !containerEle.parent().equal(editor.$textElem) &&
    //         !containerEle.equal(editor.$textElem)
    //       ) {
    //         //console.log("非顶点");
    //         if (containerEle.elems[0].tagName.toLowerCase() == "font") {
    //           //console.log("有font");
    //           editor._html.push({
    //             html: containerEle.elems[0].tagName.toLowerCase(),
    //             style: this.font_style(containerEle.elems[0]),
    //           });
    //         } else {
    //           editor._html.push({
    //             html: containerEle.elems[0].tagName.toLowerCase(),
    //             style: this.string_style(this.for_style(containerEle.elems[0].style)),
    //           });
    //         }
    //       }
    //     }
    //     // editor._html = [];
    //     // editor._html.push(containerEle.elems[0].tagName.toLowerCase());
    //     //console.log(editor);
    //     editor.$textElem.on("mouseup", (e) => {
    //       //  //console.log("监听可编辑区域", e);
    //       //  //console.log("当前选区", editor.saveSelection());
    //       editor.$textElem.off("mouseleave", editor.selection.saveRange());
    //       if (editor._brush) {
    //         let text;
    //         let style = "";
    //         let wrapStyle = "";
    //         //console.log("选区是否为空", editor.selection.isSelectionEmpty());

    //         if (editor.selection.isSelectionEmpty()) {
    //           // 如果没有选中任何内容
    //           // 折叠选区
    //           editor.selection.collapseRange();
    //         } else {
    //           let range = editor.selection.getRange();
    //           let containerElem = editor.selection.getSelectionContainerElem();
    //           // 如果只刷了一行
    //           if (!containerElem.equal(editor.$textElem)) {
    //             text = editor.selection.getSelectionText();
    //             let _html = this.format_style(editor._html, text);
    //             //渲染模板
    //             editor.cmd.do("insertHTML", _html);
    //           } else {
    //             let elements = [];
    //             let startElem = editor.selection.getSelectionStartElem();
    //             let startElemCon = startElem.parent().parent();
    //             let endElem = editor.selection.getSelectionEndElem();
    //             let endElemCon = endElem.parent().parent();

    //             elements.push({
    //               type: "start",
    //               elem: startElem,
    //               offset: range.startOffset,
    //               containerType: range.startContainer.nodeType === 1 ? "NODE" : "TEXT",
    //               container: startElemCon,
    //             });

    //             while (!startElemCon.next().equal(endElemCon)) {
    //               startElemCon = startElemCon.next();
    //               elements.push({
    //                 type: "mid",
    //                 elem: startElemCon,
    //                 container: startElemCon,
    //               });
    //             }

    //             elements.push({
    //               type: "end",
    //               elem: endElem,
    //               offset: range.endOffset,
    //               containerType: range.startContainer.nodeType === 1 ? "NODE" : "TEXT",
    //               container: endElemCon,
    //             });

    //             elements.forEach((element) => {
    //               let container = element.container;
    //               let range = editor.selection.createRangeByElem(container, null, true);

    //               if (element.type === "start") {
    //                 range.setStart(element.elem.elems[0].firstChild, element.offset);
    //                 editor.selection.saveRange(range);
    //                 editor.selection.restoreSelection();
    //                 text = editor.selection.getSelectionText();
    //                 let _html = this.format_style(editor._html, text);
    //                 editor.cmd.do("insertHTML", _html);
    //               } else if (element.type === "mid") {
    //                 text = range.toString();
    //                 let _html = this.format_style(editor._html, text);
    //                 editor.cmd.do("insertHTML", _html);
    //               } else if (element.type === "end") {
    //                 range.setEnd(element.elem.elems[0].firstChild, element.offset);
    //                 editor.selection.saveRange(range);
    //                 editor.selection.restoreSelection();
    //                 text = editor.selection.getSelectionText();
    //                 let _html = this.format_style(editor._html, text);
    //                 editor.cmd.do("insertHTML", _html);
    //               }
    //             });
    //           }
    //         }
    //       }
    //     });
    //   }
    //   // 菜单激活状态
    //   tryChangeActive() {}
    //   //  菜单点击事件
    // }

    // const E_format = "format";

    // editor.menus.extend(E_format, format);
    // editor.config.menus.push(E_format);
    // 创建编辑器
    editor.create();
    // 赋值编辑器
    this.editor = editor;
  },
  methods: {
    codeViewChange() {
      let _me = this;
      _me.editor.txt.html(_me.rule_list.detail);
    },
    //   获取国家列表
    getCountries() {
      let _me = this;

      let key = "countriesList";
      let val = _me.$common.getGlobalStorage(key);
      if (val) {
        _me.countries_list = val;
        return;
      }
      _me.$common
        .httpPost("/api/address/admin/country/find/all")
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.countries_list = res.data;
            _me.$common.setGlobalStorage(key, _me.countries_list);
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    // 获取当前活动的奖项
    getLotteryPrizeModel() {
      return new Promise((resolve, reject) => {
        let _me = this;
        _me.loading = true;
        _me.$common
          .httpPost("/api/activity/lottery/reward/admin/find/list", {
            activity_id: _me.type_id
          })
          .then(res => {
            _me.loading = false;
            if (res.code === "1000000") {
              _me.lottery_prize_list =
                res.data.array === undefined ? [] : res.data.array;

              for (let i of _me.lottery_prize_list) {
                delete i.total;
                delete i.number;
              }
              resolve();
            } else {
              _me.$message({
                message: res.message || "获取数据失败",
                type: "error"
              });
              reject();
            }
          })
          .catch(err => {
            _me.loading = false;
            _me.$message({
              message: err || "获取数据失败",
              type: "error"
            });
            reject();
          });
      });
    },
    // 获取随机码批次号列表
    getModelList() {
      let _me = this;
      let params = {
        key: _me.search_key,
        // status: _me.page_status,
        page_size: _me.page_size,
        page_number: -1,
        sort_fields: ""
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/random_code/admin/batch/find/list", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.model_list = res.data.array === undefined ? [] : res.data.array;
            // _me.page_total = res.data.total;
            _me.batchList = _me.model_list.map(item => {
              return item.batch_no;
            });
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err.message || "获取数据失败",
            type: "error"
          });
        });
    },
    getLotteryTypeModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        id: _me.type_id
      };
      _me.$common
        .httpPost("/api/activity/admin/find/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.type_model = res.data;
            if (_me.type_model.err_message) {
              _me.err_message = _me.type_model.err_message;
            }
            if (_me.type_model.rule) {
              try {
                _me.rule = JSON.parse(_me.type_model.rule);
              } catch (error) {
                console.error(error);
              }
            }
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },

    saveTypeModel() {
      let _me = this;
      _me.$refs["modelForm"].validate(valid => {
        if (valid) {
          if (_me.type_id !== undefined && _me.type_id.length > 0) {
            _me.updateTypeModel();
          } else {
            _me.newTypeModel();
          }
        } else {
          _me.$message({
            message: "请检查表单!",
            type: "error"
          });
        }
      });
    },
    updateTypeModel() {
      let _me = this;
      let start_time = this.$common.formatTimestampLongZone(new Date());
      let end_time = this.$common.getNextMonth(start_time);
      if (!this.type_model.begin) {
        this.type_model.begin = start_time;
      }
      if (!this.type_model.end) {
        this.type_model.end = end_time;
      }

      let params = {
        id: _me.type_id,
        name: _me.type_model.name,
        country_id: _me.type_model.country_id,
        alias: _me.type_model.alias,
        pc_image_url: _me.type_model.pc_image_url,
        wap_image_url: _me.type_model.wap_image_url,
        begin: _me.$common.formatTimestampLongZone(_me.type_model.begin),
        end: _me.$common.formatTimestampLongZone(_me.type_model.end),
        rule: _me.type_model.rule,
        status: _me.type_model.status,
        type: _me.type_model.type,
        pc_promotional_image_url: _me.type_model.pc_promotional_image_url,
        wap_promotional_image_url: _me.type_model.wap_promotional_image_url,
        promotional_jump_url: _me.type_model.promotional_jump_url,
        end_text: _me.type_model.end_text,
        end_jump_url: _me.type_model.end_jump_url,
        wap_end_image_url: _me.type_model.wap_end_image_url,
        pc_end_image_url: _me.type_model.pc_end_image_url
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/admin/update/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
            _me.$common.removeGlobalStorage("lotteryList");
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newTypeModel() {
      let _me = this;
      let start_time = this.$common.formatTimestampLongZone(new Date());
      let end_time = this.$common.getNextMonth(start_time);
      if (!this.type_model.begin) {
        this.type_model.begin = start_time;
      }
      if (!this.type_model.end) {
        this.type_model.end = end_time;
      }
      let params = {
        name: _me.type_model.name,
        country_id: _me.type_model.country_id,
        alias: _me.type_model.alias,
        pc_image_url: _me.type_model.pc_image_url,
        wap_image_url: _me.type_model.wap_image_url,
        begin: _me.$common.formatTimestampLongZone(_me.type_model.begin),
        end: _me.$common.formatTimestampLongZone(_me.type_model.end),
        rule: _me.type_model.rule,
        status: _me.type_model.status,
        type: _me.type_model.type,
        pc_promotional_image_url: _me.type_model.pc_promotional_image_url,
        wap_promotional_image_url: _me.type_model.wap_promotional_image_url,
        promotional_jump_url: _me.type_model.promotional_jump_url,
        end_text: _me.type_model.end_text,
        end_jump_url: _me.type_model.end_jump_url,
        wap_end_image_url: _me.type_model.wap_end_image_url,
        pc_end_image_url: _me.type_model.pc_end_image_url
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/admin/add/one", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
            _me.$common.removeGlobalStorage("lotteryList");
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    // 规则查询
    getRuleDetailModel() {
      let _me = this;
      _me.loading = true;
      let params = {
        activity_id: _me.type_id
      };
      _me.$common
        .httpPost("/api/activity/lottery/rule/admin/find/activity_id", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            let data = res.data;
            let extend;
            try {
              extend = JSON.parse(data.extend);
            } catch (error) {}
            if (Object.prototype.toString.call(extend) === "[object Object]") {
              data.extend = Object.assign({}, this.rule_list.extend, extend);
            }

            this.rule_list = Object.assign({}, this.rule_list, data);

            // 处理该活动下的奖项以及奖项排列顺序
            if (this.rule_list.rewards) {
              _me.lottery_prize_list.forEach(v => {
                _me.rule_list.rewards.forEach(e => {
                  if (v.id == e.id) {
                    _me.rewards_choose_list.push(v);
                  }
                });
              });
            }

            // let list = [];
            // 处理抽奖排列顺序
            if (this.rule_list.reward_sequence) {
              _me.rule_list.reward_sequence.forEach(s => {
                _me.lottery_prize_list.forEach(e => {
                  if (s == e.id) {
                    _me.sequence_list.push(e);
                    //   _me.order_list.push(e);
                    //   _me.sequence_order_list.push(e);
                    //   list.push(e);
                  }
                });
              });
            }

            _me.editor.txt.html(_me.rule_list.detail);
            if (
              data &&
              data.random_code_batch_list &&
              data.random_code_batch_list.length > 0
            ) {
              _me.random_code_batch_list = [
                ...new Set(data.random_code_batch_list)
              ];
            }

            // _me.order_list = _me.sequence_list
            // _me.sequence_order_list = list;

            // _me.sequence_order_list.splice(4, 0, { name: "抽奖" });
            // //             // 更换数组内的位置，进行位置布局，index1,index2数组元素索引
            // function swapArray(arr, index1, index2) {
            //   arr[index1] = arr.splice(index2, 1, arr[index1])[0];
            //   return arr;
            // }
            // swapArray(_me.sequence_order_list, 8, 3);
            // swapArray(_me.sequence_order_list, 8, 5);
            // swapArray(_me.sequence_order_list, 7, 6);

            // swapArray(_me.sequence_list, 7, 3);
            // swapArray(_me.sequence_list, 7, 4);
            // swapArray(_me.sequence_list, 6, 5);
          } else {
            _me.$message({
              message: res.message || "暂无规则，请添加该活动的规则详情",
              type: "error"
            });
          }
        })
        .catch(err => {
          console.error(err);
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    saveRuleModel() {
      if (
        this.rule_list.activity_id !== undefined &&
        this.rule_list.activity_id.length > 0
      ) {
        this.updateRuleModel();
      } else {
        this.newRuleModel();
      }
    },
    updateRuleModel() {
      let _me = this;

      let list = [];

      _me.sequence_list.forEach(v => {
        list.push(v.id);
      });

      let rewards = [];
      _me.rewards_choose_list.forEach(v => {
        rewards.push(v.id);
      });
      let params = {
        activity_id: _me.rule_list.activity_id,
        address_text: _me.rule_list.address_text,
        confirm_text: _me.rule_list.confirm_text,
        detail: _me.rule_list.detail,
        edit_text: _me.rule_list.edit_text,
        enter_description: _me.rule_list.enter_description,
        lottery_end_text: _me.rule_list.lottery_end_text,
        name_text: _me.rule_list.name_text,
        phone_text: _me.rule_list.phone_text,
        prize_fail_text: _me.rule_list.prize_fail_text,
        prize_win_text: _me.rule_list.prize_win_text,
        reward_sequence: list,
        rewards: rewards,
        start_text: _me.rule_list.start_text,
        submit_text: _me.rule_list.submit_text,
        title: _me.rule_list.title,
        enable_verify_login: this.rule_list.enable_verify_login,
        verify_type: this.rule_list.verify_type,
        upper_limit: this.rule_list.upper_limit,
        extend: JSON.stringify(this.rule_list.extend),
        random_code_batch_list: this.random_code_batch_list
      };

      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/lottery/rule/admin/update", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容保存成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    newRuleModel() {
      let _me = this;
      let list = [];

      _me.sequence_list.forEach(v => {
        list.push(v.id);
      });

      let rewards = [];
      _me.rewards_choose_list.forEach(v => {
        rewards.push(v.id);
      });
      let params = {
        activity_id: _me.type_id,
        address_text: _me.rule_list.address_text,
        confirm_text: _me.rule_list.confirm_text,
        detail: _me.rule_list.detail,
        edit_text: _me.rule_list.edit_text,
        enter_description: _me.rule_list.enter_description,
        lottery_end_text: _me.rule_list.lottery_end_text,
        name_text: _me.rule_list.name_text,
        phone_text: _me.rule_list.phone_text,
        prize_fail_text: _me.rule_list.prize_fail_text,
        prize_win_text: _me.rule_list.prize_win_text,
        reward_sequence: list,
        rewards: rewards,
        start_text: _me.rule_list.start_text,
        submit_text: _me.rule_list.submit_text,
        title: _me.rule_list.title,
        enable_verify_login: this.rule_list.enable_verify_login,
        verify_type: this.rule_list.verify_type,
        upper_limit: this.rule_list.upper_limit,
        extend: JSON.stringify(this.rule_list.extend),
        random_code_batch_list: this.random_code_batch_list
      };
      _me.loading = true;
      _me.$common
        .httpPost("/api/activity/lottery/rule/admin/add", params)
        .then(res => {
          _me.loading = false;
          if (res.code === "1000000") {
            _me.$message.success("内容添加成功");
            _me.goBack();
          } else {
            _me.$message({
              message: res.message || "获取数据失败",
              type: "error"
            });
          }
        })
        .catch(err => {
          _me.loading = false;
          _me.$message({
            message: err || "获取数据失败",
            type: "error"
          });
        });
    },
    getImageByWeb(imageType) {
      let _me = this;
      _me.$refs.lotteryFileChooseDrawerModule.showChooseDrawer(function(
        chooseFileList
      ) {
        // console.log("接收到选择的文件:", chooseFileList);
        if (chooseFileList.length > 0) {
          switch (imageType) {
            case "pc_image_url":
              _me.type_model.pc_image_url = chooseFileList[0].file_url;
              break;
            case "wap_image_url":
              _me.type_model.wap_image_url = chooseFileList[0].file_url;
              break;
            case "pc_promotional_image_url":
              _me.type_model.pc_promotional_image_url =
                chooseFileList[0].file_url;
              break;
            case "wap_promotional_image_url":
              _me.type_model.wap_promotional_image_url =
                chooseFileList[0].file_url;
              break;
            case "wap_end_image_url":
              _me.type_model.wap_end_image_url = chooseFileList[0].file_url;
              break;
            case "pc_end_image_url":
              _me.type_model.pc_end_image_url = chooseFileList[0].file_url;
              break;
            default:
              break;
          }
        }
      });
    },
    handleClose(tag, i) {
      this.sequence_list.splice(i, 1);

      //   this.order_list.splice(i, 1);

      //   this.order_list.forEach((v, vi) => {
      //     if (v.name == "抽奖") {
      //       console.log(vi);
      //       this.order_list.splice(vi, 1);
      //     }
      //   });
      //   console.log(this.order_list);
    },
    handleCommand(command) {
      this.sequence_list.push(command);
      //   this.order_list.push(command);
    },
    leaveTabOnclick(activeName, oldActiveName) {
      // eslint-disable-next-line no-console
      // console.log(activeName, oldActiveName);
    },
    updateOrder() {
      if (this.order_list.length != 8) {
        console.log("请添加排序列表为8个占位");
      } else {
        //   delete this.order_list[4]
        //   this.order_list.splice(4,1)
        //   开始排序
        // this.sequence_list.splice(4, 0, { name: "抽奖" });
        //     //             // 更换数组内的位置，进行位置布局，index1,index2数组元素索引
        //     function swapArray(arr, index1, index2) {
        //       arr[index1] = arr.splice(index2, 1, arr[index1])[0];
        //       return arr;
        //     }
        //     swapArray(this.sequence_list, 8, 3);
        //     swapArray(this.sequence_list, 8, 5);
        //     swapArray(this.sequence_list, 7, 6);
        //   this.sequence_order_list.splice(4, 0, { name: "抽奖" });

        this.arrayOrder(this.order_list);
        this.sequence_order_list = this.order_list;
        //   console.log(this.order_list,'0000000000');
        //   console.log(this.sequence_order_list);
      }
      // this.order_list.splice(4,1)
    },
    arrayOrder(list) {
      list.splice(4, 0, { name: "抽奖" });
      // 更换数组内的位置，进行位置布局，index1,index2数组元素索引
      function swapArray(arr, index1, index2) {
        arr[index1] = arr.splice(index2, 1, arr[index1])[0];
        return arr;
      }
      swapArray(list, 8, 3);
      swapArray(list, 8, 5);
      swapArray(list, 7, 6);
    },
    goBack() {
      // 返回上一级路由
      this.$router.go(-1);
    },
    // 复制信息
    copyInfo(str) {
      // 触发赋值事件，将当前的数据传值
      this.$refs.copyPaste.CopyInfo(str);
    },
    // 粘贴信息
    pasteInfo(model) {
      // 接受粘贴事件的传值数据
      if (model.id) {
        this.type_model = model;
        this.type_model.id = this.type_id;
      } else {
        this.rule_list = model;
        this.rule_list.activity_id = this.type_id;
      }
    },
    handleRemoveSku(sku) {
      this.rule_list.sku_list.splice(this.rule_list.sku_list.indexOf(sku), 1);
    },
    handleInputConfirm() {
      let inputValue = this.inputValue;
      if (inputValue) {
        if (!this.rule_list.sku_list) {
          this.rule_list.sku_list = [];
        }
        this.rule_list.sku_list.push(inputValue);
      }
      this.inputVisible = false;
      this.inputValue = "";
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(_ => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    }
  }
};
</script>

<style scoped>
.draw_list {
  height: 360px;
  border: 1px solid #000;
  text-align: center;
}

.draw_list .el-col {
  border: 1px solid #000;
  height: 120px;
  line-height: 120px;
}

.box-card {
  margin-bottom: 20px;
}

.box-card .header {
  text-align: center;
}
</style>

<style>
#lotteryRuleList .el-form-item__content {
  margin-left: 10px !important;
}
#lotteryRuleList .mgr_left {
  margin-left: 0;
}
.lottery_rule_list_form .el-card .el-card__body {
  max-width: 1000px;
  margin: auto;
}
</style>
